// Import CSS for Vite. Path is relative to this JS file
import '@css/app.css'

//
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import morph from '@alpinejs/morph';
import ui from '@alpinejs/ui';
import Swiper from 'swiper/bundle';
import Vimeo from '@vimeo/player';
import 'swiper/css/bundle';
import 'lite-youtube-embed';

window.Alpine = Alpine
Alpine.plugin(ui);
Alpine.plugin(intersect);
Alpine.plugin(persist);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(morph);

window.Swiper = Swiper;

window.Vimeo = Vimeo;


// .no-js to .js
let html = document.getElementsByTagName("html")[0];
html.className = html.className.replace("no-js", "js");

//

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html & https://nystudio107.com/docs/vite/
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR')
	})
}

Alpine.start();